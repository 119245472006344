export default {
  name: 'user-sync',
  data () {
    return {
      status: 1
    }
  },
  mounted () {
    if (this.$route.query && this.$route.query.oauth_token && this.$route.query.oauth_verifier) {
      this.storeAuthorization(this.$route.query.oauth_token, this.$route.query.oauth_verifier)
    } else {
      this.sync()
    }
  },
  computed: {

  },

  methods: {
    sync () {
      this.status = 1

      this.$store.dispatch('user_sync').then(
        (response) => {
          this.status = 0
          this.$events.$emit('user:sync:success')
        },
        (response) => {
          if (response && response.status === 401) {
            // user need to authorize the app
            this.status = 3
          } else {
            // try again
            this.status = 2
            // TODO: handle error in modal
          }
        }
      )
    },
    resync () {
      this.sync()
    },
    getAuthorization () {
      this.status = 4

      const url = this.$router.resolve({
        name: 'UsersSync'
      })

      const body = {
        url: `${window.location.origin}${url.href}`
      }

      this.$store.dispatch('user_authorize_init', body).then(
        (response) => {
          window.location.href = response.url
        },
        (response) => {
          this.status = 3
          // TODO: handle error in modal
        }
      )
    },
    storeAuthorization (token, verifier) {
      this.status = 5

      const body = {
        oauth_token: token,
        oauth_verifier: verifier
      }

      this.$store.dispatch('user_authorize_store', body).then(
        (response) => {
          // resync user
          this.sync()
        },
        (response) => {
          this.status = 3
          // TODO: handle error in modal
        }
      )
    }
  }
}
