export default {
  name: 'system-sync',
  data () {
    return {
      status: 1
    }
  },
  mounted () {
    this.sync()
  },
  computed: {

  },

  methods: {
    sync () {
      this.status = 1

      this.$store.dispatch('countries_get').then(
        (response) => {
          this.status = 0
          this.$events.$emit('system:sync:success')
        },
        (response) => {
          // try again
          this.status = 2
          // TODO: handle error in modal
        }
      )
    },
    resync () {
      this.sync()
    },
    cancel () {
      this.status = 0
      this.$events.$emit('system:sync:skip')
    }
  }
}
