import SystemSync from '@/components/SystemSync/main'
import UserSync from '@/components/UserSync/main'

export default {
  name: 'users-sync',
  components: {
    UserSync,
    SystemSync
  },
  data () {
    return {
      currentComponent: 'user-sync'
    }
  },
  created () {
    this.$events.$once('user:sync:success', () => {
      this.swapComponent('system-sync')
    })
    this.$events.$once('system:sync:success', () => {
      this.proceed()
    })
    this.$events.$once('system:sync:skip', () => {
      this.proceed()
    })
  },
  beforeDestroy () {
    this.$events.$off('user:sync:success')
    this.$events.$off('system:sync:success')
    this.$events.$off('system:sync:skip')
  },

  computed: {
  },
  watch: {
  },
  methods: {
    swapComponent (component) {
      this.currentComponent = component
    },
    proceed () {
      if (this.$store.state.redirect_path) {
        const path = this.$store.state.redirect_path
        // clear the redirect path
        this.$store.dispatch('set_redirect', null)

        this.$router.push(path)
      } else {
        this.$router.push({ name: 'Home' })
      }
    }
  }
}
